import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import startCase from 'lodash/startCase';
import IMask from 'imask';
import Swiper from 'swiper/swiper-bundle.esm';
import 'swiper/swiper-bundle.min.css';
import * as Stores from 'src/effector/stores.js';
import {
  store as drawerStore,
  actions as drawerActions,
} from 'src/effector/drawer';
import {
  formulaByPeriod,
  stores as paymentPeriodStores,
} from 'src/effector/paymentPeriod';
import {
  store as analyticStore,
  actions as analyticActions,
} from 'src/effector/analytics';

import tippy from 'tippy.js';
import Scrollbar from 'smooth-scrollbar';

window.tippy = tippy;

try {
  window.mp = analyticActions;
  window.analytics = analyticActions;
} catch (e) {
  console.error(e.message);
}

document.addEventListener('alpine:init', () => {
  Object.keys(Stores).forEach(storeName => {
    const effectorStore = Stores[storeName];

    Alpine.store(storeName, {
      init() {
        this.actions = effectorStore.actions;

        effectorStore.store.watch(store => {
          this.store = store;
        });
      },
    });
  });

  Alpine.data('mask', () => ({
    zipCodeMask: el => {
      return IMask(el, { mask: '00000' });
    },
    phoneMask: el => {
      return IMask(el, {
        mask: '000-000-0000',
        commit(value, masked) {
          return masked.unmaskedValue;
        },
      });
    },
  }));

  Alpine.data('floatingLabel', () => ({
    shown: false,

    init(element) {
      if (element?.value || document.activeElement === element) {
        this.shown = true;
      }
    },

    onBlur(event) {
      this.shown = !!event.target.value;
    },

    onChange() {
      this.shown = true;
    },

    onFocus() {
      this.shown = true;
    },
  }));

  Alpine.store('analytics', {
    fbqAddToCart() {
      try {
        window.fbq =
          window.fbq || ((a, b) => console.log('AddToCart event: ', a, b));
        window.fbq('track', 'AddToCart');
      } catch (error) {
        console.log('AddToCart event: ', error);
      }
    },

    sendDataToKlavio({ quantity, product_data, lineItems, orderTotal }) {
      const productOptions = {
        productId: product_data.staggered
          ? product_data.id.front
          : product_data.id,
        price: product_data.staggered
          ? product_data.price.front
          : product_data.price,
        url: product_data.url,
        brandName: product_data.brand_name,
        modelName: product_data.model_name,
        productSizeDesc: product_data.size_desc,
        productSku: product_data.sku,
        imageUrl: product_data.image_url,
      };

      const items = lineItems || [];
      items.push({
        Sku: productOptions.productSku,
        ProductName: `${productOptions.brandName} ${productOptions.modelName} ${productOptions.productSizeDesc}`,
        Quantity: quantity,
        ItemPrice: parseFloat(productOptions.price),
        RowTotal: parseFloat(productOptions.price) * quantity,
        ProductURL: `https://www.tireagent.com/${productOptions.url}`,
        ImageURL: productOptions.imageUrl,
      });
      const valueToSend = {
        $value:
          parseFloat(orderTotal) + parseFloat(productOptions.price) * quantity,
        AddedItemProductName: `${productOptions.brandName} ${productOptions.modelName} ${productOptions.productSizeDesc}`,
        AddedItemProductID: productOptions.productId,
        AddedItemSKU: productOptions.productSku,
        AddedItemURL: `https://www.tireagent.com${productOptions.url}`,
        AddedItemImageURL: productOptions.imageUrl,
        AddedItemPrice: parseFloat(productOptions.price),
        AddedItemQuantity: quantity,
        ItemNames: items.map(o => o.ProductName),
        Items: items,
      };

      try {
        window._learnq.push(['track', 'Added to Cart', valueToSend]);
      } catch (error) {
        console.log(['track', 'Added to Cart', valueToSend]);
      }
    },

    criteo(products) {
      const skus = products.map(({ sku }) => sku);
      const deviceType = /iPad/.test(navigator.userAgent)
        ? 't'
        : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
            navigator.userAgent,
          )
        ? 'm'
        : 'd';
      try {
        window.criteo_q.push(
          { event: 'setAccount', account: 83067 },
          { event: 'setSiteType', type: deviceType },
          { event: 'viewList', item: skus },
        );
      } catch (error) {
        console.log('criteo', deviceType, skus);
      }
    },
  });

  Alpine.data('dropdown', () => ({
    open: false,

    toggle() {
      this.open = !this.open;
    },
  }));

  Alpine.data('pricerange', range => ({
    init() {
      if (range) {
        const [min, max] = range.split(';');
        this.minprice = +min;
        this.maxprice = +max;
      }
      this.mintrigger();
      this.maxtrigger();
    },

    minprice: 0,
    maxprice: 2000,
    min: 0,
    max: 2000,
    minthumb: 0,
    maxthumb: 0,

    isDisabledPriceRange() {
      if (this.minprice === 0 && this.maxprice === 2000) {
        return true;
      }
      return false;
    },

    mintrigger() {
      this.minprice = Math.min(this.minprice, this.maxprice - 100);
      this.minthumb =
        ((this.minprice - this.min) / (this.max - this.min)) * 100;
    },

    maxtrigger() {
      this.maxprice = Math.max(this.maxprice, this.minprice + 100);
      this.maxthumb =
        100 - ((this.maxprice - this.min) / (this.max - this.min)) * 100;
    },

    rangeprice() {
      return [this.minprice, this.maxprice].join(';');
    },

    clear() {
      this.minprice = 0;
      this.maxprice = 2000;
      this.mintrigger();
      this.maxtrigger();
    },
  }));

  Alpine.store('filters_model', {
    state: {},

    applied(group_name) {
      return this.state
        .find(({ filter_name }) => group_name === filter_name)
        .labels.filter(({ checked }) => checked).length;
    },
  });

  Alpine.store('add_to_cart', {
    disabled: false,
    disable() {
      this.disabled = true;
    },
  });

  Alpine.data('featured_filters_input', type => ({
    input: {
      ':checked': function() {
        return !!this.$store.featured_filters.state[type];
      },
      '@change': function() {
        this.$store.featured_filters.apply(
          type,
          !this.$store.featured_filters.state[type],
        );
        setTimeout(() => this.$dispatch('submit-filter-form'), 0);
      },
    },
  }));

  Alpine.data('product_visibility', ({ feature }) => ({
    visible: {
      'x-show': function() {
        if (this.$store.search_result.state === 'loading') {
          return false;
        }
        if (!this.$store.featured_filters.empty() && feature) {
          return this.$store.featured_filters.state[feature];
        }
        if (this.$store.featured_filters.empty()) {
          return true;
        }
        return false;
      },
    },
  }));

  Alpine.store('featured_filters', {
    state: {
      deals: false,
      cheap: false,
      best_warranty: false,
      all_weather: false,
    },

    apply(key, value) {
      if (value) {
        analyticActions.featreudFilterApplied({
          eventName: {
            deals: 'featured filter sr_deals',
            best_warranty: 'featured filter sr_great warranty',
            cheap: 'featured filter sr_best value',
            all_weather: 'featured filter sr_superior rainsnow',
          }[key],
        });
        this.clearAllExceptOf(key);
      }
      this.state[key] = value;
    },

    clearAllExceptOf(key) {
      Object.keys(this.state)
        .filter(name => name != key)
        .forEach(name => (this.state[name] = false));
    },

    applied() {
      return Object.values(this.state).filter(Boolean).length;
    },

    empty() {
      return Object.values(this.state).every(val => !val);
    },
  });

  Alpine.data('sticky_image', () => ({
    sticky: false,

    scroll() {
      const topBar = document.getElementById('product-page-top-bar');
      const headerHeight = 159;
      const topBarHeight = topBar.offsetHeight;
      this.sticky =
        this.$store.viewport.isDesktop &&
        document.documentElement.scrollTop >= topBarHeight + headerHeight;
    },
  }));

  Alpine.data('sticky_button', () => ({
    show: false,

    scroll() {
      const footer = document.getElementsByTagName('footer')[0];
      const mainSection = document.getElementById('main-section');
      const { scrollTop } = document.documentElement;

      this.show =
        !this.$store.viewport.isDesktop &&
        scrollTop >= mainSection.offsetHeight &&
        scrollTop + window.innerHeight <= footer.offsetTop;
    },
  }));

  Alpine.data('request_js', () => ({
    state: 'idle',

    async fetch(url) {
      this.state = 'loading';
      await $.getScript(url);
      this.state = 'done';
    },
  }));

  Alpine.data('tooltip', () => ({
    open: false,

    toggle() {
      this.open = !this.open;
    },
  }));

  Alpine.store('search_result', {
    state: 'idle',
  });

  Alpine.store('viewport', {
    isDesktop: window.innerWidth >= 1200,
    isTablet: window.innerWidth >= 768 && window.innerWidth < 1200,
    isMobile: window.innerWidth < 768,
  });

  Alpine.data('filter_sort_reset_form', () => ({
    submit() {
      this.$refs.form.submit();
      this.$store.drawer.close();
      this.$store.search_result.state = 'loading';
    },
  }));

  Alpine.data('filter_sort_form', () => ({
    submit() {
      setTimeout(() => {
        this.$refs.form.submit();
        this.$store.drawer.close();
        this.$store.search_result.state = 'loading';
      }, 0);
    },

    changeRimSize(target) {
      target.disabled = true;
      const [sizeValue, staggeredValue] = target.value.split('"');
      this.$refs.filters_size_field.value = sizeValue;
      this.$refs.filters_staggered_field.value = isEmpty(staggeredValue)
        ? 'No'
        : 'Yes';
      this.submit();
    },
  }));

  Alpine.data(
    'price_based_on_payment_period',
    (front = { price: 0, quantity: 0 }, rear = { price: 0, quantity: 0 }) => ({
      init() {
        this.paymentPeriod = paymentPeriodStores.$selectedPaymentPeriod.getState();

        paymentPeriodStores.$selectedPaymentPeriod.watch(paymentPeriod => {
          this.paymentPeriod = paymentPeriod;
        });
      },

      front: {
        price: front.price,
        markdown: front.markdown,
        quantity: front.quantity,
      },

      rear: {
        price: rear.price,
        markdown: rear.markdown,
        quantity: rear.quantity,
      },

      isStaggered: rear.price > 0,
      paymentPeriod: {},

      calculateBasedOnPeriod(type = 'front') {
        const priceByPeriod = formulaByPeriod[this.paymentPeriod.key](
          this[type].price,
          this[type].quantity,
        );
        return this.formatPrice(priceByPeriod);
      },

      calculateMarkdownBasedOnPeriod(type = 'front') {
        const priceByPeriod = formulaByPeriod[this.paymentPeriod.key](
          this[type].markdown,
          this[type].quantity,
        );
        return this.formatPrice(priceByPeriod);
      },

      calculateTotal() {
        const price = [
          this.front.price * this.front.quantity,
          this.rear.price * this.rear.quantity,
        ].reduce((acc, sum) => acc + sum, 0);
        return this.formatPrice(price);
      },

      displayPricePerItem(type = 'front') {
        return this.formatPrice(this[type].price);
      },

      displayMarkdownPerItem(type = 'front') {
        return this.formatPrice(this[type].markdown);
      },

      percentageDifferenceBetweenMarkdownAndPrice(type = 'front') {
        const { price, markdown } = this[type];
        if (!this.shouldShowMarkdown(type)) {
          return 0;
        }
        const difference = markdown - price;
        const percentageDifference = (difference / markdown) * 100;
        return Math.round(percentageDifference);
      },

      shouldShowMarkdown(type = 'front') {
        const { price, markdown } = this[type];
        return markdown !== 0 && markdown < 999 && markdown > price;
      },

      calculateMinimal(type = 'front') {
        return this.formatPrice(
          formulaByPeriod.monthly(this[type].price, this[type].quantity),
        );
      },

      formatPrice(number) {
        return `$${Number.parseFloat(number).toFixed(2)}`;
      },

      totalQuantity() {
        return +this.front.quantity + +this.rear.quantity;
      },

      onChange({ target: { value } }, type = 'front') {
        this[type].quantity = value;
      },

      dollarsToCents(amount) {
        return amount
          .split('$')
          .join('')
          .split('.')
          .join('');
      },
    }),
  );

  Alpine.data('price', (price, quantity = 4) => ({
    quantity: quantity,

    calculateTotal() {
      const totalPrice = price * this.quantity;
      return this.formatPrice(totalPrice);
    },

    formattedPrice() {
      return this.formatPrice(price);
    },

    formatPrice(number) {
      return `$${Number.parseFloat(number).toFixed(2)}`;
    },
  }));

  Alpine.data('available_sizes', () => ({
    active: {},
    isLoading: false,

    get isDisabled() {
      return isEmpty(this.active);
    },

    isActive(id) {
      return this.active.id === id;
    },

    selectSize(item) {
      if (item.id === this.active.id) {
        this.active = {};
      } else {
        this.active = item;
      }
    },
  }));

  Alpine.data('imageViewer', () => ({
    imageUrl: '',

    fileChosen(event) {
      if (!event.target.files.length) return;
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = e => {
        this.imageUrl = e.target.result;
      };
    },
  }));

  Alpine.store('drawer', {
    init() {
      drawerStore.watch(store => {
        this.type = store.type;
        this.isOpened = store.isOpened;
        this.origin = store.origin;
        this.state = store.state;
        this.config = store.config;
      });

      this.open = drawerActions.open;
      this.close = drawerActions.close;
    },
  });

  Alpine.store('slider', {
    init() {
      this.init = () => {
        this.swiper = new Swiper('.deals-swiper', {
          loop: true,
          slidesPerView: 2,
          slidesPerGroup: 2,
          spaceBetween: 20,
          autoplay: {
            delay: 5000,
            disableOnInteraction: true,
          },
          pagination: {
            el: '.deals-swiper-pagination',
          },
          navigation: {
            nextEl: '.deals-swiper-button-next',
            prevEl: '.deals-swiper-button-prev',
          },
        });
      };
    },
  });

  Alpine.store('reviewsSlider', {
    init() {
      this.init = () => {
        this.swiper = new Swiper('.reviews-swiper', {
          loop: true,
          slidesPerView: 1.25,
          spaceBetween: 20,
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
          },
          centeredSlides: true,
          breakpoints: {
            768: {
              slidesPerView: 2,
              spaceBetween: 30,
              slidesPerGroup: 2,
              centeredSlides: false,
            },
            1200: {
              slidesPerView: 3,
              spaceBetween: 30,
              slidesPerGroup: 3,
            },
          },
        });
      };
    },
  });

  Alpine.store('utils', {
    init() {
      this.isEqual = isEqual;
      this.isEmpty = isEmpty;
      this.startCase = startCase;
      this.omit = omit;
      this.scrollbar = Scrollbar.init;
    },
  });

  Alpine.data('imageViewer', () => ({
    imageUrl: '',

    fileChosen(event) {
      if (!event.target.files.length) return;
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = e => {
        this.imageUrl = e.target.result;
      };
    },
  }));

  Alpine.store('effectorAnalytics', {
    init() {
      analyticStore.watch(store => {
        this.store = store;
      });

      this.actions = analyticActions;
    },
  });

  Alpine.data('populateOrder', () => ({
    submit({ detail: { variant_id, plus_size_id, quantity } }) {
      appendHiddenInputsToForm(
        { variant_id, plus_size_id, quantity },
        this.$refs.form,
      );
      this.$refs.form.submit();
      this.$store.search_result.state = 'loading';
    },
  }));

  Alpine.data('populateOrderStaggered', () => ({
    submit({
      detail: {
        variant_id1,
        plus_size_id_1,
        quantity1,
        variant_id2,
        plus_size_id_2,
        quantity2,
      },
    }) {
      appendHiddenInputsToForm(
        {
          variant_id1,
          plus_size_id_1,
          quantity1,
          variant_id2,
          plus_size_id_2,
          quantity2,
        },
        this.$refs.form,
      );
      this.$refs.form.submit();
      this.$store.search_result.state = 'loading';
    },
  }));

  Alpine.data('zendeskProactiveChat', () => ({
    message: '',
    isHelperShown: false,
    textAreaRows: 1,

    scaleInput() {
      this.textAreaRows = 4;
    },

    startTimer(timer = 60000) {
      setTimeout(() => (this.isHelperShown = true), timer);
    },

    sendMessage() {
      this.isHelperShown = false;
      zE('webWidget', 'open');
      const status = zE('webWidget:get', 'display');
      if (status === 'chat' && this.message) {
        zE('webWidget', 'chat:send', this.message);
      }
      zE('webWidget', 'updateSettings', {
        webWidget: {
          chat: {},
          contactForm: {
            fields: [{ id: 'description', prefill: { '*': this.message } }],
          },
        },
      });
    },
  }));

  const appendHiddenInputsToForm = (fields, form) => {
    Object.entries(fields).forEach(([key, value]) => {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = key;
      input.value = value;
      form.appendChild(input);
    });
  };
});
